@use "~@ircc-ca/ds-sdc-core/util/color" as color;
@use "~@ircc-ca/ds-sdc-core/util/theme" as theme;
@use "~@ircc-ca/ds-sdc-core/util/device" as device;
@use "~@ircc-ca/ds-sdc-core/util/size" as size;
@use "~@ircc-ca/ds-sdc-core/tokens/template-const" as template-const;
@use "~@ircc-ca/ds-sdc-core/tokens/sizes" as token-size;
@use "~@ircc-ca/ds-sdc-core/typography/typography" as typography;
@use "~@ircc-ca/ds-sdc-core/tokens/text" as text;
@use "sass:map";

@mixin selector {
  lib-form-radio#{template-const.$escape} {
    @content;
  }
}

@mixin create {
  @include selector() {
    @include layout();
  }
}

@mixin small-styles() {
  .form-radio {
    &__label {
      font-size: 1rem;
    }
  }
}

@mixin large-styles() {
}

@mixin layout {
  .form-radio {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 30rem;

    &__label {
      font-size: 1.25rem;
      font-weight: 700;
      display: flex;
      gap: 0.25rem;
    }

    &__label-info {
      display: flex;
      gap: 0.25rem;
    }

    &__help-text,
    &__hint {
      color: #6f6f71;
      font-weight: 400;
      font-size: 1rem;
    }

    &__shift {
      margin-top: 0.35rem;
    }

    &__required-icon {
      color: var(--critical-text);
      font-size: 1rem;
      align-self: flex-start;
      svg {
        margin-bottom: 0.35rem;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__options {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 0;
      margin-top: 0;
    }

    &__option {
      margin: 0;
    }

    &__error {
      color: var(--critical-text);

      &::before {
        content: "";
        position: relative;
        left: 0;
        height: 0.8rem;
        width: 0.8rem;
        display: inline-block;
        background-image: url("/assets/lib-assets/icons/circle-empty-alert.svg");
        background-size: contain;
        background-repeat: no-repeat;
        color: var(--critical-text);
        font-weight: 400;
      }
    }
  }

  .sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
