@use "~@ircc-ca/ds-sdc-core/index" as ircc-ds;
/* use override styles for PRDI */
@use "ircc-design-system-extension/overrides.scss" as prdi-ds;
@include ircc-ds.theme-init-required(
  ircc-ds.palette-journeylab(),
  default,
  large
);
@include ircc-ds.element-styles();

/* override DS styles for PRDI */
@include prdi-ds.element-styles();

@import "config";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* compile global styles from all code-producing partials */
@import "partials/forms";
@import "partials/a11y";
@import "partials/tables";
@import "partials/typography";
