/* FONTS */
@font-face {
  font-family: "Lato";
  font-weight: normal;
  font-display: swap;
  src: url("/assets/fonts/LatoLatin-Regular.woff2") format("woff2"),
    url("/assets/fonts/LatoLatin-Regular.woff") format("woff"),
    url("/assets/fonts/LatoLatin-Regular.otf") format("otf"),
    url("/assets/fonts/LatoLatin-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: bold;
  font-display: swap;
  src: url("/assets/fonts/LatoLatin-Bold.woff2") format("woff2"),
    url("/assets/fonts/LatoLatin-Bold.woff") format("woff"),
    url("/assets/fonts/LatoLatin-Bold.otf") format("otf"),
    url("/assets/fonts/LatoLatin-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: normal;
  font-display: swap;
  src: url("/assets/fonts/NotoSans-Regular.woff2") format("woff2"),
    url("/assets/fonts/NotoSans-Regular.woff") format("woff"),
    url("/assets/fonts/NotoSans-Regular.otf") format("otf"),
    url("/assets/fonts/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans";
  font-weight: bold;
  font-display: swap;
  src: url("/assets/fonts/NotoSans-Bold.ttf") format("truetype");
  /* Safari, Android, iOS */
}

/* FONT STACKS */

$font-primary: "Noto Sans", "Tahoma", "Arial", "Helvetica", sans-serif;
$font-secondary: "Lato", "Verdana", "Geneva", sans-serif;

/* TYPOGRAPHY */

$font-size-base: 16px;
$font-size-large: 1.375rem; // 22px
$font-size-small: 1rem; // 16px
$font-size-xsmall: 0.875rem; // 14px

/* body text */
@mixin body-text {
  font-family: $font-primary;
  font-weight: normal;
  font-size: $font-size-base;
  line-height: 1.875;
}

@mixin body-text-large {
  font-family: $font-primary;
  font-weight: normal;
  font-size: $font-size-large;
  line-height: 1.875;
}

@mixin body-text-small {
  font-family: $font-primary;
  font-weight: normal;
  font-size: $font-size-small;
  line-height: 1.75;
}

@mixin body-text-xsmall {
  font-family: $font-primary;
  font-weight: normal;
  font-size: $font-size-xsmall;
  line-height: 1.75;
}

/* other styles */
@mixin lead-text {
  font-family: $font-primary;
  font-weight: normal;
  font-size: 1.625rem;
  line-height: 1.875;
}

@mixin body-text-2 {
  font-family: $font-primary;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.875;
}

@mixin body-text-3 {
  font-family: $font-primary;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.875;
}

@mixin small-text {
  font-family: $font-primary;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.875;
}

/* Header and footer styles */
@mixin bookends-text($color) {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: 0.0125rem;
  color: $color;
}

@mixin bookends-text-small($color) {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.375;
  letter-spacing: 0.0125rem;
  color: $color;
}
