// This file imports only non-code-producting partials
// i.e. variables, mixins, and functions

@import "config/typography";
@import "config/colours";
@import "config/_grid.scss";
@import "config/animations";

// The styles below don't have a home yet, and can be
// refactored into their own partials whenever it makes sense to do so

$border-radius: 4px;

@mixin visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  overflow: hidden;
  white-space: nowrap;
}

@mixin focus-border {
  border-color: $blue;
  outline: none;
  box-shadow: 0 0 0 1px $blue;
}

@mixin unstyle-ul {
  list-style: none;
  padding: 0;
}

@mixin unstyle-button {
  border: none;
  background: transparent;
  font-size: inherit;
}
