// Add default margins and font weights to text in PRDI
h1,
:root h1,
:host h1 {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  font-weight: 700;
}

h2,
:root h2,
:host h2 {
  margin-bottom: 2rem;
  margin-top: 2;
  font-weight: 700;
}

h3,
:root h3,
:host h3 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

h4,
:root h4,
:host h4 {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 700;
}

h5,
:root h5,
:host h5 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

h6,
:root h6,
:host h6 {
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-weight: 700;
}

// h1 + h2,
// :root h1 + :root h2,
// :host h1 + :host h2 {
//   margin-top: 0;
// }

// h2 + h3,
// :root h2 + :root h3,
// :host h2 + :host h3 {
//   margin-top: 0;
// }

// h3 + h4,
// :root h3 + :root h4,
// :host h3 + :host h4 {
//   margin-top: 0;
// }

// h4 + h5,
// :root h4 + :root h5,
// :host h4 + :host h5 {
//   margin-top: 0;
// }

// h5 + h6,
// :root h5 + :root h6,
// :host h5 + :host h6 {
//   margin-top: 0;
// }

p {
  margin-bottom: 1rem;
  margin-top: 1rem;
  // font-size: 1.25rem;
  // line-height: 1.75rem;
}

// body,
// :root body,
// :host body {
//   font-size: 1.25rem;
//   line-height: 1.75rem;
// }

* > .body1 {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

* > .body2 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

* > .body3,
* > .body4 {
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

// existing styling for PRDI
.external-link:focus {
  outline: 1px solid $blue;
}

.external-link::after {
  content: "";
  background-color: currentColor;
  mask-image: url("/assets/images/icons/external-link.svg");
  -webkit-mask-image: url("/assets/images/icons/external-link.svg");
  display: inline-block;
  height: 0.7em;
  width: 0.7em;
  margin-left: 0.25em;
}

// override anchor links to align like buttons
a:where([category="primary"]),
a:where([category="secondary"]) {
  text-align: center;
}
