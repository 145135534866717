@use "~@ircc-ca/ds-sdc-core/util/color" as color;
@use "~@ircc-ca/ds-sdc-core/util/theme" as theme;
@use "~@ircc-ca/ds-sdc-core/util/device" as device;
@use "~@ircc-ca/ds-sdc-core/util/size" as size;
@use "~@ircc-ca/ds-sdc-core/tokens/template-const" as template-const;
@use "~@ircc-ca/ds-sdc-core/tokens/sizes" as token-size;
@use "~@ircc-ca/ds-sdc-core/typography/typography" as typography;
@use "~@ircc-ca/ds-sdc-core/tokens/text" as text;
@use "sass:map";

@mixin selector {
  lib-form-textarea#{template-const.$escape} {
    @content;
  }
}

@mixin create {
  @include selector() {
    @include layout();
  }
}

@mixin size-large() {
}

@mixin size-small() {
  .form-textarea {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &__label {
      font-size: 1rem;
    }

    &__field {
      font-size: 0.875rem;
    }
  }
}

@mixin at-resize($value: none) {
  .resize-#{$value} {
    textarea {
      resize: $value;
    }
  }
}

@mixin layout {
  .form-textarea {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 30rem;

    &__label {
      font-size: 1.25rem;
      font-weight: 700;
      display: flex;
      gap: 0.25rem;
    }

    &__shift {
      margin-top: 0.35rem;
    }

    &__required-icon {
      color: var(--critical-text);
      font-size: 1rem;
      align-self: flex-start;
      svg {
        margin-bottom: 0.35rem;
      }
    }

    &__field {
      font-size: 1rem;
      margin-bottom: 0;
      margin-top: 0;
    }

    &__error {
      color: var(--critical-text);

      &::before {
        content: "";
        position: relative;
        left: 0;
        height: 0.8rem;
        width: 0.8rem;
        display: inline-block;
        background-image: url("/assets/lib-assets/icons/circle-empty-alert.svg");
        background-size: contain;
        background-repeat: no-repeat;
        color: var(--critical-text);
        font-weight: 400;
      }
    }

    &__readonly {
      width: 100%;
      border-style: solid;
      border-width: 1px;
      border-radius: 4px;
      color: hsl(240deg, 2%, 9%);
      color: var(--text-disabled);
      border-color: var(--border-disabled);
      background-color: var(--surface1);
      padding: 1rem;
    }
  }

  @include at-resize(none);
  @include at-resize(both);
  @include at-resize(horizontal);
  @include at-resize(vertical);
}
