form {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

fieldset {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  legend {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5rem;
    font-size: 1.75rem;
    line-height: 2.5rem;
  }
}

ol {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  legend {
    padding: 0;
    margin: 0;
  }
}
