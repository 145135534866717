/* PALETTE */
$indigo-1: #c7cfef;
$indigo-2: #4f5b8b;
$indigo-3: #131f4c;
$indigo-4: #000a3d;

$teal-1: #c2f2ef;
$teal-2: #a1e5e1;
$teal-3: #7accc8;
$teal-4: #5dbfbc;

$cream: #f7f2d2;
$orange: #ff9700;
$brown: #614d29;
$pale-red: #f1e7e6;
$red: #d1080c;
$brick: #a70609;
$dark-brick: #782e2b;
$pale-green: #d8eeca;
$green: #1c5c00;
$greencheck: #278400;
$dark-green: #2b542c;
$light-aqua: #d5f8ff;
$aqua: #17596d;
$dark-aqua: #245269;
$blue: #0a75c6;
$dark-blue: #284162;
$navy: #26374a;
$purple: #29156b;
$black: #252525;
$lighter-grey: #f8f8f8;
$light-grey: #f5f5f5;
$lighter-medium-grey: #efefef;
$medium-grey: #dedede;
$light-dark-grey: #c9c7c5;
$dark-grey: #636367;
$white: #ffffff;
$light-light-grey: #e1e4e7;
$soft-border: #bbbbbb;
$error-red-color: #903534;
$soft-error-red-color: #f1e7e6;

/* FUNCTIONAL COLOURS */

/* main theme */
$primary-1: $indigo-1;
$primary-2: $indigo-2;
$primary-3: $indigo-3;
$primary-4: $indigo-4;

$secondary-1: $teal-1;
$secondary-2: $teal-2;
$secondary-3: $teal-3;
$secondary-4: $teal-4;

$success: $pale-green;
$warning: $cream;
$danger: $pale-red;

/* text */
$main-text: $black;
$light-text: $white;

/* links */
$a: $dark-blue;
$a-active: $blue;
$a-visited: $purple;

/* buttons */

/* form colours */
$focus-input: $lighter-grey;
$field-highlight: rgba($indigo-1, 0.4);

/* alerts */
$alert-info-bg: $medium-grey;
$alert-info-icon: $black;
$alert-info-text: $dark-grey;

$alert-warning-bg: $cream;
$alert-warning-icon: $orange;
$alert-warning-text: $brown;

$alert-danger-bg: $pale-red;
$alert-danger-icon: $red;
$alert-danger-text: $dark-brick;

$alert-success-bg: $pale-green;
$alert-success-icon: $green;
$alert-success-text: $dark-green;

/* background */
$bg-light: $white;
$bg-medium: $primary-4;
$bg-dark: $navy;

/* case statuses */
$case-initiated-bg: $cream;
$case-initiated-text: $brown;
$case-in-review-bg: $light-aqua;
$case-in-review-text: $dark-aqua;
$case-revision-bg: $pale-red;
$case-revision-text: $brick;
$case-completed-bg: $pale-green;
$case-completed-text: $dark-green;
$case-rejected-bg: $light-grey;
$case-rejected-text: $black;
