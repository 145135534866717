@import "config";

//generic styling for all tables

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;

  @media (max-width: $xl-breakpoint) {
    overflow-x: scroll;
  }

  //  sets tables to scroll horizontally, if wider than viewport
  &-scrolling-wrapper {
    width: 100%;
    max-width: 100vw;
    @media (max-width: $xl-breakpoint) {
      overflow-x: scroll;
    }
  }

  thead {
    @include body-text-small;
    color: $indigo-3;
    font-weight: bold;
  }

  th {
    padding: 20px !important; //just to overwrite material design
    border: 1px solid $medium-grey;
    text-align: left;
    background-color: $lighter-grey;
    overflow-wrap: break-word;
  }

  td {
    @include body-text-small;
    padding: 20px !important; //just to overwrite material design
    border: 1px solid $medium-grey;
    color: $indigo-3;
    overflow-wrap: break-word;
  }

  .empty-table {
    td {
      border: none;
      text-align: center;
    }
  }
}
